import './bootstrap';

// Adding jQuery
// https://laravel-mix.com/docs/5.0/jquery-ui
window.Popper = require('@popperjs/core').default; // This belongs to animate.css
window.$ = window.jQuery = require('jquery');

// Adding animate.css
// https://stackoverflow.com/questions/61684007/animate-css-does-not-seem-to-work-with-laravel-7-10-3-in-chrome-and-ff
window.animate = require('animate.css');

// Example on how to add further things
// import 'jquery-ui/ui/widgets/datepicker.js';

window.Cropper = require('cropperjs');

window.jQueryFileUpload = require('blueimp-file-upload');

//import * as FilePond from 'jquery-filepond';
//window.FilePond = require('jquery-filepond');
